



















































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSidebarLayout from '@/components/VsSidebarLayout/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'
import VsMapFieldCard from '@/modules/lists/components/VsMapFieldCard/Index.vue'
import VsMapFieldCardMobile from '@/modules/lists/components/VsMapFieldCardMobile/Index.vue'
import {
    CustomFieldTypeEnum,
    CustomField,
} from '@/utils/customFields'
import { createCustomField } from '@/api/consoleApi/customFields'
import VsCustomFieldsModal from '@/modules/lists/components/VsCustomFieldsModal/Index.vue'
import axios from 'axios'

@Component({
    name: 'VsMapField',
    components: {
        VsContainer,
        VsMapFieldCard,
        VsMapFieldCardMobile,
        VsCustomFieldsModal,
        VsSidebarLayout,
    },
})
export default class extends Vue {
    @Prop() customFields!: any
    @Prop() list!: any
    @Prop() preprocessData!: any
    @Prop({ required: false, default: () => [] }) value!: any[]

    private loading = false
    private mappedFields: any[] = []
    private showNotificationError = false
    $refs: any

    get listId () {
        return this.$route?.params?.listId || ''
    }

    get isSuppressionImport () {
        return this.$route.matched.find((el: any) => el.name === 'listImportIndexSuppression')
    }

    get newCustomFields () {
        if (!this.isSuppressionImport) return this.customFields
        return [
            {
                id: 'email',
                name: 'Indirizzo email',
                validation: {
                    required: false,
                },
            },
            {
                id: 'mobile',
                name: 'Telefono cellulare principale',
                validation: {
                    required: false,
                },
            },
        ]
    }

    get requiredFields () {
        return this.customFields.filter((el: any) => el.validation.required)
    }

    get totalRecords () {
        return get(this.preprocessData, 'pre_processing_info.total_records', 0)
    }

    get rows () {
        return get(this.preprocessData, 'pre_processing_info.header', [])
    }

    get customFieldSelected (): any[] {
        return this.mappedFields.map(el => el.customFieldId)
    }

    get missingRequiredFields () {
        const customFieldsMissing = []

        for (const customField of this.requiredFields) {
            if (this.mappedFields.filter(el => el.customFieldId === customField.id).length === 0) customFieldsMissing.push(customField.name)
        }
        return customFieldsMissing
    }

    get skipFirstRow () {
        return this.preprocessData.strategy_settings.skip_first_row
    }

    get isUrlStrategy () {
        return this.preprocessData.strategy === 'url'
    }

    get isDbStrategy () {
        return this.preprocessData.strategy === 'db'
    }

    private getOptionValue (field: string, index: number) {
        const fieldName = this.skipFirstRow || this.isUrlStrategy || this.isDbStrategy ? field : `field${index + 1}`
        const foundField = this.mappedFields.find(el => el.field === fieldName)
        return foundField ? foundField.customFieldId : 'notImport'
    }

    private autoSelection () {
        const newFields: any[] = []

        this.preprocessData.pre_processing_info.header.forEach((header: any, index: number) => {
            if (this.isSuppressionImport && newFields.length >= 1) return
            const found = this.newCustomFields.find((cf: any) => cf.name.toLowerCase() === header.toLowerCase())
            if (found && !this.mappedFields.find(mf => mf.customFieldId === found.id)) {
                newFields.push({
                    customFieldId: found.id,
                    field: this.skipFirstRow || this.isUrlStrategy || this.isDbStrategy ? header : 'field' + (index + 1),
                })
            }
        })

        if (newFields.length === 0) {
            this.$root.$vsToast({
                heading: 'La mappatura automatica ha dato 0 risultati',
                message: 'Il sistema non è riuscito a trovare in automatico una corrispondenza tra i nomi dei campi e le intestazioni',
                timeout: 8000,
                aspect: VsToastAspectEnum.alert,
            })
        } else {
            this.mappedFields = newFields
            this.$root.$vsToast({
                heading: newFields.length > 1 ? `Sono stati mappati ${newFields.length} campi personalizzati` : `È stato mappato ${newFields.length} campo personalizzato`,
                timeout: 5000,
                aspect: VsToastAspectEnum.success,
            })
        }
    }

    beforeMount () {
        const mappedFields = []
        for (const fieldId in this.value) {
            mappedFields.push({
                customFieldId: parseInt(fieldId),
                field: this.value[fieldId],
            })
        }
        this.mappedFields = mappedFields
    }

    private openCustomFieldModal (customField?: CustomField) {
        this.$refs.vsCustomFieldsModal.openModal(
            {
                unique: false,
                showMobile: !customField && !this.customFields.find((el: any) => el.type === CustomFieldTypeEnum.mobile),
                showEmail: !customField && !this.customFields.find((el: any) => el.type === CustomFieldTypeEnum.email),
            },
            customField,
        )
    }

    private async createCustomField (customField: any) {
        this.$refs.vsCustomFieldsModal.loading = true
        try {
            customField.recipient_id = this.listId
            await createCustomField(customField)
            this.$emit('custom-field-generated')
            this.$refs.vsCustomFieldsModal.loading = false
            this.$refs.vsCustomFieldsModal.closeModal()
        } catch (e) {
            let message = ''
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 403) {
                    this.$root.$vsToast({
                        timeout: 3000,
                        heading: 'Hai raggiunto il limite di campi personalizzati creabili per il tuo piano',
                        aspect: VsToastAspectEnum.alert,
                    })
                    this.$refs.vsCustomFieldsModal.loading = false
                    return
                }

                const placeholderError = get(e, 'response.data.message.placeholder', false)
                if (placeholderError) {
                    message = 'Il placeholder inserito non è valido, prova con un altro valore'
                }
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante la creazione del campo personalizzato',
                message,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
            this.$refs.vsCustomFieldsModal.loading = false
        }
    }

    private mapField (event: any) {
        this.showNotificationError = false
        this.mappedFields.filter((el: any) => {
            if (el.field === event.field) {
                const index = this.mappedFields.indexOf(el)
                if (index === -1) return
                this.mappedFields.splice(index, 1)
            }
        })
        if (event.customFieldId === 'notImport') return
        this.mappedFields.push(event)
    }

    private confirmMap () {
        if (this.missingRequiredFields && this.missingRequiredFields.length > 0) {
            this.showNotificationError = true
            return
        }
        if (this.isSuppressionImport && this.mappedFields.length === 0) {
            this.showNotificationError = true
            return
        }
        this.$emit('submit', this.mappedFields)
    }
}
