

























































import { CustomField, CustomFieldTypeEnum } from '@/utils/customFields'
import { get } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsMapFieldCardMobile',
})
export default class extends Vue {
    @Prop() customFields!: any
    @Prop() preprocessData!: any
    @Prop() index!: any
    @Prop() customFieldSelected!: any
    @Prop({ required: false, default: false }) disabled!: boolean
    @Prop({ required: false, default: 'notImport' }) optionValue!: any

    get skipFirstRow () {
        return this.preprocessData.strategy_settings.skip_first_row
    }

    get isUrlStrategy () {
        return this.preprocessData.strategy === 'url'
    }

    get isDbStrategy () {
        return this.preprocessData.strategy === 'db'
    }

    get header () {
        return this.skipFirstRow || (this.isUrlStrategy || this.isDbStrategy) ? this.preprocessData.pre_processing_info.header[this.index] : 'field' + (this.index + 1)
    }

    get selectedCfObject (): CustomField | null {
        if (!this.optionValue || this.optionValue === 'notImport' || this.customFields.length === 0) {
            return null
        }
        return this.customFields.find((el: any) => el.id === this.optionValue)
    }

    get notificationMessage () {
        if (!this.selectedCfObject) return false
        switch (this.selectedCfObject.type) {
            case CustomFieldTypeEnum.mobile:
                return 'Il valore di questo campo deve essere un numero cellulare e deve iniziare col prefisso internazionale (es: +39)'
            case CustomFieldTypeEnum.multipleChoise:
            case CustomFieldTypeEnum.dropdown:
                return 'Il valore di questo campo deve essere uno tra i seguenti: ' + this.selectedCfObject.field_options.map(el => el.value).join(', ')
            case CustomFieldTypeEnum.checkboxes:
                return 'Il valore di questo campo deve essere uno o più valori separati da virgola tra i seguenti: ' + this.selectedCfObject.field_options.map(el => el.value).join(', ')
            case CustomFieldTypeEnum.dateField:
                return 'Il formato di questo campo deve essere YYYY-MM-DD'
            case CustomFieldTypeEnum.timeField:
                return 'Il formato di questo campo deve essere hh:mm:ss'
            default:
                return false
        }
    }

    get firstThreeRowValues () {
        const fieldValue: any[] = []
        this.preprocessData.pre_processing_info.first_three_records.forEach((el: any) => {
            fieldValue.push((this.isUrlStrategy || this.isDbStrategy) ? get(el, this.header, '-') : el[this.index])
        })
        return fieldValue
    }

    get mapOptions () {
        const customFieldName: any[] = [{
            value: 'notImport',
            label: 'Non importare',
        }]
        this.customFields.forEach((el: any) => {
            const required = el.validation.required ? ' (obbligatorio)' : ''
            customFieldName.push({
                value: el.id,
                label: el.name + required,
                disabled: this.customFieldSelected.includes(el.id),
            })
        })
        return customFieldName
    }

    // mounted () {
    //     this.customFields.forEach((el: any) => {
    //         if ((el.name.toLowerCase() === this.header.toLowerCase()) && !this.customFieldSelected.includes(el.id)) {
    //             this.mapField(el.id)
    //         }
    //     })
    // }

    private mapField (customFieldId: string) {
        this.$emit('mapped-field', {
            customFieldId,
            field: this.header,
        })
    }

    private getValue (value: any) {
        if (this.isUrlStrategy || this.isDbStrategy) {
            return get(value, this.header, '-')
        }
        return value
    }
}
